import {Button, Radio} from "semantic-ui-react"
import React from "react"

export function RadioGroup({ onChange, options, value, rid, fid }) {
    const handleClick = (val) => {
        onChange(val)
    }
    return options.map((option, i) => (
        <Button.Group key={rid + option}>
            <Button onClick={() => handleClick(option)}>
                <Radio
                    name={"radio_ynna_" + rid + fid}
                    label={option}
                    value={option}
                    checked={value === option}
                />
            </Button>
            {i % 2 === 0 && i !== options.length -1 && <Button.Or/>}
        </Button.Group>)
    )
}
