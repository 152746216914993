const cleanLink = (link) => {
    if (/https?:\/\//.test(link)) {
        return link
    }
    return `https://${link}`
}

export const redirectOnSave = (realm, dbid, qid, redirectTo) => {
    if (redirectTo) {
        window.location.href = cleanLink(redirectTo)
    } else {
        window.location.href = "https://" + realm + ".quickbase.com/db/" + dbid + "?a=dr&rid=" + qid
    }
}