import React, {useEffect, useState} from "react";
import { Dimmer, Loader, Form, Container} from 'semantic-ui-react'
import logo from '../logo.png';
import { QuestionnaireHeader } from "./QuestionnaireHeader"
import {getQuestionnaire, login, saveQuestionnaire, getImageUrl, saveFile} from "../utils/api"
import {groupRecords, mapRecords} from "../utils/mapRecord"
import {validateConfig} from "../utils/validateConfig"
import {QuestionGroups} from "./QuestionGroups"
import validate from "../utils/validate"
import {mapToQuickbase} from "../utils/mapToQuickbase"
import {mapExistingVals} from "../utils/mapExistingVals"
import {redirectOnSave} from "../utils/createRedirectLink"

export const Questionnaire = (props) => {
  const [auth, setAuth] = useState()
  const [loading, setLoading] = useState()
  const [loaderText, setLoaderText] = useState("Initiating Questionnaire...")
  const [values, setValues] = useState({})
  const [config, setConfig] = useState()
  const [groups, setGroups] = useState([])

  useEffect(() => {
    setLoading(true)
    setLoaderText("Authorizing...")
    if (!auth) {
      login(props.configId).then(responseText => {
        setAuth(responseText)
        setLoaderText("Grabbing Configuration...")
        return fetchConfig(responseText, props.qid);
      }).catch(e => {
        alert("Authorization failed. Please Contact Administrator.");
      })
    }
  }, [])

  const setValue = (rid, fid, val) => {
    let vals = {
      ...values,
      [rid]: {
        ...values[rid],
        [fid]: val
      },
    }
    setValues(vals)
  }

  const doCancel = () => {
    let dbid = config.qb.questionnaire?.dbid;
    let realm = config.qb.realm;
    window.location.href = "https://" + realm + ".quickbase.com/db/" + dbid + "?a=dr&rid=" + props.qid
  }

  const doSave = async () => {
    if (!auth) {
      alert("Authorization failed. Please Contact Administrator.")
      return
    }
    if (!validate(values, groups, config.qb.questions)) {
      alert('Please fill out the required fields')
      return
    }

    setLoading(true)
    setLoaderText('Saving...')

    try {
      const {inputData, filesToSend} = await mapToQuickbase(values)
      await saveQuestionnaire(auth, inputData)
      if (filesToSend.length > 0) {
        setLoading(true)
        setLoaderText("Uploading Files...")
        for (const file of filesToSend) {
          await saveFile(auth, file) // save files serially
        }
      }
      redirectOnSave(config.qb.realm, config.qb.questionnaire?.dbid, props.qid, config.redirectTo)
    } catch (e) {
      setLoading(false)
      setLoaderText('Loading...')
      alert(e.message)
    }

  }

  const fetchConfig = async (token, qid) => {
    const response = await getQuestionnaire(token, qid)
    const questions = response.questions?.data
    validateConfig(response.config)
    const config = response.config;
    const mappedQuestions = mapRecords(questions, config?.qb?.questions)
    const groups = groupRecords(mappedQuestions, config?.qb?.questions)
    setLoaderText("Generating Markup...")
    setLoading(false)
    setConfig(config)
    setGroups(groups)
    setValues(mapExistingVals(config?.qb?.questions, groups))
  }

  return (
      <div>
        <QuestionnaireHeader loading={loading}
                             logo={config ? getImageUrl(config.iconUrl) : logo}
                             save={doSave}
                             cancel={doCancel}/>
        <Container key='questionnaireContainer'>
          <Dimmer active={loading}>
            <Loader key='questionnaireLoader'>{loaderText}</Loader>
          </Dimmer>
          <Form>
            <QuestionGroups
                config={config?.qb?.questions}
                groups={groups}
                formState={values}
                setValue={setValue}/>
          </Form>
        </Container>
        {config ? <QuestionnaireHeader
            loading={loading}
            logo={config ? getImageUrl(config.iconUrl) : logo}
            save={doSave}
            cancel={doCancel}/> : null
        }
      </div>
  )
}

export default Questionnaire;
