import {getWritableRidFromConfig} from "./getWritableRidFromConfig"

export const mapExistingVals = (qbConfig, groups) => {
  let existingValues = {};

  // gid is just the index. because groups is a list of list
  for (let gid in groups) {
    let questions = groups[gid];
    for (let q in questions) {
      // set existing value
      let question = questions[q];
      let questionTypes = question.questionTypes;
      for (let i = 0; i < questionTypes?.length; i++) {
        let questionTypeInfo = questionTypes[i].split(". ");
        let questionType = questionTypeInfo[1];

        let qRid = question.recordRef["rid"];
        if (!existingValues[qRid]) {
          existingValues[qRid] = {}
        }
        const ridToWriteTo = getWritableRidFromConfig(qbConfig, questionType, question.recordRef)
        existingValues[qRid][ridToWriteTo] = question.recordRef[ridToWriteTo]
      }
    }
  }

  return existingValues;

}
