import {getWritableRidFromConfig} from "./getWritableRidFromConfig"

const getKeyFromType = (question, questionsConfig) => {
    const keys = []
    for (const questionType of question.questionTypes) {
        const realType = questionType.split(". ")
        keys.push(getWritableRidFromConfig(questionsConfig, realType.at(-1), question.recordRef))
    }
    return keys
}

const validate = (values, groups, questionsConfig) => {
    let errors = {}

    for (const group of groups) {
        if (group) {
            for (const question of group) {
                if (question && question.required) {
                    const id = question.qRid
                    const valueDoesNotExist = !values[id]
                    if (valueDoesNotExist) {
                        errors[id] = `${question.questionLabel} is required`
                        continue
                    }
                    const valueHasNoOptions = Object.keys(values[id]).length === 0
                    if (valueDoesNotExist || valueHasNoOptions) {
                        errors[id] = `${question.questionLabel} is required`
                        continue
                    }
                    const keysToCheck = getKeyFromType(question, questionsConfig)
                    for (const key of keysToCheck) {
                        if (key && (!values[id][key] || (Array.isArray(values[id][key]) && values[id][key].length === 0))) {
                            errors[id] = `${question.questionLabel} is required`
                            break
                        }
                    }
                }
            }
        }
    }

    return Object.keys(errors).length === 0
}

export default validate
