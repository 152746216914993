import React, {useMemo} from "react";
import {Input, Dropdown, Table, CommentText} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import {RadioGroup} from "./questionTypes/RadioGroup"

const minWidth = '8rem'
// formState is just formState[recordId]
export default function QuestionBlock({ question, setValue, recordFormState = {}, qbConfig } ) {
    const {
        qRid,
        questionTypes: subQuestionTypes,
        placeHolder,
        dropdownOptions,
        selOptions
    } = question
    const changeDate = (e, {value}) => {
        // we only use hooks here for visual rendering, the data is actually set in the props.setValue
        setValue(qRid, qbConfig.answerDate, value);
    }

    const changeFile = async (e) => {
        const list = Array.from(e.target.files)
        if (list) {
            setValue(qRid, qbConfig.answerFileAttachment, {filename: list[0].name, fileRef: list[0]})
        }
    }

    const changeAnswerFreeResponse = (e, {value}) => {
        setValue(qRid, qbConfig.answerFreeResponse, value);
    }

    const changeSelection = (e, {value}) => {
        setValue(qRid, qbConfig.answerOptsSel, value);
    }

    const changeMultiSelection = (e, {value}) => {
        setValue(qRid, qbConfig.answerOptsSel, value);
    }

    const changeAnswerYN = (value) => {
        setValue(qRid, qbConfig.answerYN, value);
    }

    // for some reason we need to sort
    const sortedSubQuestionTypes = useMemo(() => subQuestionTypes.sort((a, b) => {
        let [aInfo] = a.split(". ");
        let aNum = parseInt(aInfo);
        let [bInfo] = b.split(". ");
        let bNum = parseInt(bInfo);

        if (isNaN(aNum) || isNaN(bNum))
            return -1;

        return +aNum - +bNum;
    }), [subQuestionTypes])

    return (
        <Table basic="very">
            <Table.Body>
                <Table.Row>
                    {sortedSubQuestionTypes.map(qType => {
                        const [, questionType] = qType.split(". ")

                        switch (questionType) {
                            case "Yes/No/NA":
                                return (
                                    <Table.Cell collapsing key={"cell_yn_" + qRid}>
                                        <RadioGroup options={['Yes', 'No', 'N/A']}
                                                    fid={qbConfig.answerYN}
                                                    value={recordFormState[qbConfig.answerYN]}
                                                    onChange={changeAnswerYN}/>
                                    </Table.Cell>
                                )
                            case "Yes/No":
                                return (
                                    <Table.Cell collapsing key={"cell_yn_" + qRid}>
                                        <RadioGroup options={['Yes', 'No']}
                                                    style={{minWidth}}
                                                    fid={qbConfig.answerYN}
                                                    value={recordFormState[qbConfig.answerYN]}
                                                    onChange={changeAnswerYN}/>
                                    </Table.Cell>
                                )
                            case "Date":
                                let value = recordFormState[qbConfig.answerDate]
                                if (value) {
                                    value = new Date(value + (new Date().getTimezoneOffset() * 60 * 1000))
                                } else {
                                    value = ''
                                }
                                return (
                                    <Table.Cell key={"cell_date_" + qRid}>
                                        <SemanticDatepicker type="basic"
                                                            style={{minWidth}}
                                                            fluid
                                                            format={"MM-DD-YYYY"}
                                                            value={value}
                                                            onChange={changeDate}
                                        />
                                    </Table.Cell>
                                );
                            case "Single-Select Choice":
                                return (
                                    <Table.Cell key={"cell_ss_" + qRid}>
                                        <Dropdown
                                            style={{minWidth}}
                                            placeholder={placeHolder}
                                            fluid
                                            clearable
                                            selection
                                            options={dropdownOptions}
                                            value={recordFormState[qbConfig.answerOptsSel]}
                                            onChange={changeSelection}
                                        />
                                    </Table.Cell>
                                );
                            case "Multi-Select Choice":
                                let values = recordFormState[qbConfig.answerOptsSel]
                                if (typeof values === 'string') {
                                    values = selOptions.map(opt => opt.key)
                                }
                                return (
                                    <Table.Cell key={"cell_ms_" + qRid}>
                                        <Dropdown
                                            placeholder={placeHolder}
                                            fluid
                                            style={{minWidth}}
                                            multiple
                                            clearable
                                            selection
                                            options={dropdownOptions || []}
                                            value={values}
                                            onChange={changeMultiSelection}
                                        />
                                    </Table.Cell>
                                );
                            case "File Upload":
                                return (
                                    <Table.Cell key={"cell_file_" + qRid}>
                                        <Input type='file' placeholder={placeHolder}
                                            // value={recordFormState[qbConfig.answerFileAttachment]?.filename}
                                               onChange={changeFile}
                                               style={{minWidth}}
                                        />
                                    </Table.Cell>
                                );
                            case "Free Response":
                                return (
                                    <Table.Cell key={"cell_input_" + qRid}>
                                        <Input type='text' placeholder={placeHolder}
                                               value={recordFormState[qbConfig.answerFreeResponse]}
                                               onChange={changeAnswerFreeResponse}
                                               style={{minWidth}}
                                        />
                                    </Table.Cell>
                                );
                            case "No Response":
                                return (
                                    <Table.Cell key={"cell_input_" + qRid}>
                                        <CommentText style={{minWidth}}>
                                            {placeHolder}
                                        </CommentText>
                                    </Table.Cell>
                                )
                        }
                        console.error(`invalid question type ${questionType}`)
                        return null
                    })}
                </Table.Row>
            </Table.Body>
        </Table>
    )
}