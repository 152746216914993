import './App.css';
import Questionnaire from './components/Questionnaire';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const qid = urlParams.get("qid");
  const cid = urlParams.get("cid")
  return (  
    <Questionnaire configId={cid} qid={qid} />
  );
}

export default App;
