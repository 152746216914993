export const getWritableRidFromConfig = (questionsConfig, questionType, recordRef) => {
    switch (questionType) {
        case "Yes/No/NA":
            if (recordRef[questionsConfig.answerYN])
                return questionsConfig.answerYN
            break
        case "Yes/No":
            if (recordRef[questionsConfig.answerYN])
                return questionsConfig.answerYN
            break
        case "Date":
            if (recordRef[questionsConfig?.answerDate])
                return questionsConfig.answerDate
            break
        case "Single-Select Choice":
            if (recordRef[questionsConfig.answerOptsSel])
                return questionsConfig.answerOptsSel
            break
        case "Multi-Select Choice":
            if (recordRef[questionsConfig.answerOptsSel])
                return questionsConfig.answerOptsSel
            break
        case "File Upload":
            if (recordRef[questionsConfig.answerFileAttachment])
                return questionsConfig.answerFileAttachment
            break
        case "Free Response":
            if (recordRef[questionsConfig.answerFreeResponse])
                return questionsConfig.answerFreeResponse
            break
        default:
            return ''
    }
}
