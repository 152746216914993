import {BUCKET, ENDPOINT} from "./constants"
import { getTempTicketFromUrl, hasValidTicket, storeTicket} from "./authTokenHelper"

export const login = async (configId) => {
    const existingTicket = hasValidTicket(configId)
    if (existingTicket) {
        return existingTicket
    }
    const tempTicket = getTempTicketFromUrl()
    const response = await fetch(ENDPOINT + '/login', {
        method: 'POST',
        body: JSON.stringify({
            ticket: tempTicket,
            configId: configId
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        }
    })
    if (response.ok) {
        const ticket = await response.text()
        storeTicket(ticket, configId)
        return ticket
    } else {
        throw new Error('login failed. see console')
    }
}

export const getQuestionnaire = async (jwt, qid) => {
    const response = await fetch(ENDPOINT + "/questionnaire?qid=" + qid, {
        headers: {
            "Authorization": "Bearer " + jwt
        }
    })
    return await response.json()
}

export const saveQuestionnaire = async (jwt, questions) => {
    let response = await fetch(ENDPOINT + "/questionnaire", {
        method: "POST",
        body: JSON.stringify(questions),
        headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + jwt
        }
    })
    if (!response.ok) {
        throw new Error('save failed. see console')
    }
}

export const saveFile = async (jwt, {file, rid, fid}) => {
        let response = await fetch(ENDPOINT + `/questionnaire/file/${rid}/${fid}?key=${file.name}`, {
        method: "POST",
        body: file,
        headers: {
            "Authorization": "Bearer " + jwt
        }
    })
    if (!response.ok) {
        if (response.status === 413) throw new Error(`could not save ${file.name}. files over 5 MB are not allowed`)
        throw new Error(`could not save ${file.name}.`)
    }
}

export const getImageUrl = (path) => {
    if (!path) return undefined
    return `https://${BUCKET}.s3.us-west-2.amazonaws.com/${path}`
}
