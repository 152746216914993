export const mapToQuickbase = async (vals) => {
    //build array of records
    const combinedAnswers = {}
    const filesToSend = []

    for (let rid in vals) {
        if (!combinedAnswers[rid]) {
            combinedAnswers[rid] = {}
        }
        for (let fid in vals[rid]) {
            if (vals[rid][fid] instanceof Date) {
                const date = vals[rid][fid].toISOString().split('T')[0]
                combinedAnswers[rid][fid] = date
            } else if (Array.isArray(vals[rid][fid])) {
                combinedAnswers[rid][fid] = vals[rid][fid].join("; ");
            } else if (vals[rid][fid]?.fileRef) {
                const fileObject = {
                    rid,
                    fid,
                    file: vals[rid][fid].fileRef,
                    filename: vals[rid][fid].filename
                }
                filesToSend.push(fileObject)
            } else {
                combinedAnswers[rid][fid] = vals[rid][fid]
            }
        }
    }

    const inputData = []
    for (let rid in combinedAnswers) {
        inputData.push({rid, ...combinedAnswers[rid]})
    }
    return {inputData, filesToSend}
}
