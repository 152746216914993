export const mapOptions = (qbOptionsList)=> {
    if (!qbOptionsList) return []
    return qbOptionsList.toString().split(/;\s?/).map((option) => {
        return {
            key: option,
            text: option,
            value: option,
        }
    })
}

export const mapRecord = (rec, questionConfig) => {
    const mappedQuestion = {
        qRid: rec.rid,
        questionTypes: (rec[questionConfig.questionType]??'').split(";"),
        questionLabel: rec[questionConfig.question],
        placeHolder: rec[questionConfig.placeholderText],
        dropdownOptions: mapOptions(rec[questionConfig.answerOpts]),
        selOptions: mapOptions(rec[questionConfig.answerOptsSel]),
        helpText: rec[questionConfig.helpText],
        groupId: rec[questionConfig.groupNumber],
        groupTitle: rec[questionConfig.groupTitle],
        recordRef: rec,
        required: rec[questionConfig.required] === 'Required'
    }
    if (!mappedQuestion.groupId && mappedQuestion.groupId !== 0) {
        mappedQuestion.groupId = 'none'
    }
    return mappedQuestion
}

export function mapRecords(questionRecords, questionConfig) {
  if (!questionConfig || !questionRecords) return []
  const mappedRecords = []

  for (const rec of questionRecords) {

    for (const key in questionConfig) {
      if (typeof rec[questionConfig[key]] === "undefined")
        rec[questionConfig[key]] = ""
    }
    if (!rec[questionConfig.questionType]) {
      continue
    }
    const mappedQuestion = mapRecord(rec, questionConfig)
    mappedRecords.push(mappedQuestion)
  }
  return mappedRecords
}

export const groupRecords = (mappedRecords, questionConfig) => {
  if (!questionConfig || !mappedRecords) return []
  const questionGroups = {}

  for (const rec of mappedRecords) {
      if (!questionGroups[rec.groupId]) {
          questionGroups[rec.groupId] = []
      }
      questionGroups[rec.groupId].push(rec)
  }
  if (checkIfGroupsNumeric(Object.keys(questionGroups))) {
      return Object.keys(questionGroups)
          .map(key => parseInt(key))
          .sort()
          .map(key => questionGroups[key])
  }
    return Object.keys(questionGroups)
        .sort()
        .map(key => questionGroups[key])
}

const checkIfGroupsNumeric = (keys) => keys.some(key => !Number.isNaN(parseInt(key)))
