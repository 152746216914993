import React from "react"
import {Button, Image, Segment, Table} from "semantic-ui-react"

export const QuestionnaireHeader = ({cancel, save, logo, loading}) => (
    <Segment textAlign="center">
      <Table basic="very">
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center">
              {logo ? <Image src={logo}
                style={{maxHeight: "40px", margin: "0 auto"}}
              /> : null}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button disabled={loading} onClick={cancel}>
                CANCEL
              </Button>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button disabled={loading} primary onClick={save}>
                SAVE
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
)
