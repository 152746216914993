const keys = ["dbid","relQuestionnaire","qNumber","question","questionType","required","placeholderText","helpText","group","groupNumber","groupTitle","answerDate","answerFreeResponse","answerFileAttachment","answerYN","answerOpts","answerOptsSel"]

export const validateConfig = (config) => {
    const errors = []
    if (!config?.qb) errors.push('no qb object found')
    else if (!config.qb.questions) errors.push('no questions object found in qb')
    else {
        keys.forEach(key => !Object.keys(config.qb.questions).includes(key) ? errors.push('missing ' + key) : undefined)
    }
    if (errors.length > 0) {
        alert('errors found in the config please see the administrator\n' + errors.join('\n'))
        console.error(errors)
    }
}
