import {Form, Icon, Popup} from "semantic-ui-react"
import QuestionBlock from "./QuestionBlock"
import React from "react"

export const QuestionWrapper = ({
    question,
    setValue,
    formState,
    config
}) => (
    <Form>
        <Form.Field key={"field_" + question.qRid}>
            <QuestionLabel question={question} />
            <QuestionBlock
                question={question}
                setValue={setValue}
                recordFormState={formState[question.qRid]}
                qbConfig={config}
            />
        </Form.Field>
    </Form>
)

const QuestionLabel = ({ question }) => (
    <label>
        {question.questionLabel}
        {question.required ? <span style={{color: "red", display: "inline"}}>*</span> : null}
        &nbsp;
        <Popup
            content={question.helpText}
            trigger={<Icon
                name='question circle outline'
                style={{
                    "display": (question.helpText) ? "inline-block" : "none",
                    "cursor": "pointer",
                    "marginLeft": "8px"
                }}
            />}
        />
    </label>
)

