import {Divider, Header} from "semantic-ui-react"
import React from "react"
import {QuestionWrapper} from './QuestionWrapper'

const GroupHeader = ({ title }) => (
    <div>
        <br/>
        <Divider horizontal>
            <Header as='h4'>
                {title}
            </Header>
        </Divider>
    </div>
)


export const QuestionGroups = ({groups, config, setValue, formState}) => groups.map((group, gid) => (
    <div key={gid}>
        <GroupHeader title={group[0].groupTitle} key={"group_" + gid}/>
        {config ? group.map(question => (
            <QuestionWrapper
                key={question.qRid}
                config={config}
                setValue={setValue}
                formState={formState}
                question={question}
            />
        )) : null}
    </div>
))
