const storageKey = (configId) => 'q_auth_' + configId

export const hasValidTicket = (configId) => {
    const ticket = window.localStorage.getItem(storageKey(configId))
    if (ticket) {
        const parts = ticket.split('.')
        const decoded = atob(parts[1])
        const metadata = JSON.parse(decoded)
        const expiry = metadata.exp
        if (expiry && expiry > Date.now()) {
            return ticket
        }
    }
}

export const getTempTicketFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const ticketParam = urlParams.get('ticket')
    const ticket = decodeURIComponent(atob(ticketParam))
    return ticket
}

export const storeTicket = (ticket, configId) =>{
    window.localStorage.setItem(storageKey(configId), ticket)
}
